import React from 'react';

import { Container } from './styles';

const GeneralInfo = () => (
  <div style={{margin: 15}}>
      <h2>Orientações Gerais para Uso do Aplicativo</h2>
      
      <br/>
      <br/>
      <h3>Ativação de Créditos:</h3>
      <br/>
      <h4>- Como ativar o Crédito Digital?</h4>
      <p>A ativação é feita na opção "Estacionar Agora" do aplicativo. Insira a placa, confirme/defina a localização do automóvel a ser estacionado na Zona Azul, confira/defina a regra de estacionamento e toque no botão “Avançar”.</p>
      <p>Se houver saldo suficiente para a transação, basta confirmar os dados na tela de confirmação e finalizar o processo.</p>
      <p>Se não houver saldo, abaixo dos dados da transação serão disponibilizados os campos para inserção dos dados para pagamento.</p>
      <p>Após a conclusão da ativação, um e-mail com um comprovante da transação será enviado.</p>
      <br/>

      <h4>- Posso estender o tempo de estacionamento?</h4>
      <p>Sim. É possível estender o tempo até um minuto antes de o tempo expirar. Porém, para regras que possuem apenas a regra de curta duração, é possível estender apenas uma vez para o mesmo logradouro. Para estender o tempo utilize o botão "Renovar por + 1 período" no cronômetro.</p>
      <br/>

      <h4>- Não utilizei o tempo total. Como faço para cancelar o tempo restante?</h4>
      <p>Não é permitida a cobrança fracionada ou pausar o tempo da ativação. Entretanto, é possível cancelar a utilizaçao do crédito caso não ultrapasse os 15 primeiros minutos. O crédito é devolvido ao usuário. Mas atenção: Caso o usuário volte a ativar a utilização de uma vaga no mesmo logradouro antes de completar 2 (duas) horas do cancelamento, ele não poderá utilizar os 15 minutos de tolerância, devendo nesse caso ser cobrado o valor do uso normalmente.</p>
      <br/>

      <h4>- Se ficar sem internet, como ativar um Crédito Digital</h4>
      <p>Na impossibilidade de fazer ativações a partir de seu aparelho, entre em contato conosco pelo telefone (71) 4042 2812 para realizarmos a transação, caso tenha saldo.</p>
      <p>Ou também se pode ativar um Crédito Digital mandando SMS para o número 29092 com os seguintes termos:</p>
      <p>ATIVECARTAO (placa do veículo) (CPF/CNPJ) (horas de permanência) (preço)</p>
      <p>Importante: preste atenção nos espaços em branco.</p>
      <p>Exemplo:</p>
      <p>ATIVECARTAO AAA1111 12345678912 2h 3</p>
      <br/>

      <h4>- Posso utilizar o aplicativo em qualquer vaga do Estacionamento Rotativo?</h4>
      <p>Com o aplicativo é possível utilizar quaisquer vagas do Estacionamento Rotativo dentro do município de Salvador, sempre respeitando as regras especificadas na placa de sinalização da via.</p>
       
      <br/>
      <br/>
      <h3>Compra de Créditos:</h3>
      <br/>
      <h4>- Como comprar Crédito Digital?</h4>
      <p>A aquisição de Créditos Digitais pode ser feita por meio do menu "Comprar Créditos".</p>
      <p>É possível pagar utilizando Cartão de crédito, Pix e Boleto bancário.</p>
      <p>Atenção: Para estacionar no Estacionamento Rotativo é preciso ATIVAR os Créditos Digitais comprados.</p>
      <br/>
      <h4>- Meu saldo expira?</h4>
      <p>Não.</p>
      <br/>
      <h4>- Como cadastrar/excluir os dados do cartão de crédito?</h4>
      <p>Para cadastrar um novo cartão de crédito no App, faça uma compra utilizando os dados do cartão a cadastrar e deixe marcada a opção "Salvar Cartão".</p>
      <p>A visualização e exclusão de cartões de crédito está disponível na opção "Cartões de Crédito" do menu.</p>
      <p>Caso queira excluir um cartão, toque no ícone da lixeira localizado à direita do cartão desejado e confirme a exclusão.</p>
      <br/>
      <h4>- Excluí meu comprovante. Como solicito a segunda via?</h4>
      <p>A segunda via dos recibos pode ser solicitada diretamente no aplicativo. Basta acessar a opção "Histórico de Uso" e filtrar o período desejado. Selecione os recibos desejados e depois toque no botão para reenviar. A segunda via será enviada ao e-mail cadastrado.</p>
      <p>Se não estiver recebendo os comprovantes, confirme se o e-mail cadastrado está correto na opção "Minha Conta" do menu e verifique o Lixo Eletrônico/Spam.</p>

      <br/>
      <br/>
      <h3>Fiscalização:</h3>
      <br/>
      <h4>- Como é feita a fiscalização do Estacionamento Rotativo?</h4>
      <p>Os agentes de fiscalização possuem um dispositivo móvel para consultar quais veículos estão regulares, ou seja, têm Crédito Digital ativo. Não é necessário deixar nenhum comprovante no veículo.</p>
      <br/>
      <h4>- Em quais cidades posso utilizar este aplicativo para estacionar?</h4>
      <p>O aplicativo permite o estacionamento no Rotativo apenas no munícipio de Salvador.</p>

      <br/>
      <br/>
      <h3>Senha:</h3>
      <br/>
      <h4>- Esqueci minha senha. Como faço para recuperar?</h4>
      <p>A recuperação da senha pode ser feita diretamente pelo aplicativo através da opção “Esqueceu a senha?” na tela de acesso.</p>
      <br/>
      <h4>- Como alterar minha senha?</h4>
      <p>A alteração de senha é feita via aplicativo na opção "Minha Conta" do menu, tocando no aba "Alterar Senha”.</p>

      <br/>
      <br/>
      <h3>Placas e Alarmes:</h3>
      <br/>
      <h4>- Como cadastrar/excluir uma placa?</h4>
      <p>O cadastro e exclusão de placas está disponível na opção “Meus Veículos” do menu. Para cadastrar, basta tocar no botão "Cadastrar Novo Veículo” e inserir as informações necessárias.</p>
      <p>Caso queira excluir uma placa, toque no ícone de lixeira localizado à direita da placa desejada e confirme a exclusão.</p>
      <br/>
      <h4>- Como altero os alarmes de expiração?</h4>
      <p>As notificações podem ser programadas através da opção "Alarmes" no menu do aplicativo. Para ativar ou desativar, toque no marcador da configuração desejada. O alarme funciona mesmo sem acesso à internet ou deslogado da conta.</p>

      <br/>
      <br/>
      <h2>Maiores informações sobre o Estacionamento Rotativo - Zona Azul Digital, em Salvador</h2>
      <br/>
      <br/>
      <p>- Um crédito de Zona Azul Digital pode ser utilizado durante todo o seu tempo de vigência, mesmo que exista deslocamento do local original da compra, desde que o novo local tenha a mesma regra de períodos válida. Caso seja infringida essa regra o veículo poderá ser autuado.</p>
      <br/>
      <p>- Sobre as regras e valores de estacionamento, têm-se as seguintes áreas:</p>
      <p>-- Curta Duração: 2 horas, no valor de R$ 3,00;</p>
      <p>-- Média Duração: 6 horas, no valor de R$ 6,00;</p>
      <p>-- Longa Duração: 12 horas, no valor de R$ 9,00;</p>
      <p>-- ORLA - Média Duração: 6 horas, no valor de R$ 6,00;</p>
      <p>-- Eventos Pequeno e Grande Porte: nos valores de R$ 10,00 e R$ 20,00, respectivamente.</p>
      <br/>
      <p>- A regulação das vagas é feita com placas (estacionamento regulamentado), contendo as informações básicas para a utilização do sistema.</p>
      <br/>
      <p>- O Estacionamento Rotativo de Salvador conta atualmente com 18.225 vagas que funcionam ao longo das vias públicas e em bolsões conforme as regras mostradas acima. A fiscalização das áreas de Estacionamento Rotativo é feita pela TRANSALVADOR.</p>
      <br/>
      <p>- A Zona Azul de Salvador possui o horário de funcionamento de Segunda a Sábado (07h às 19h) ou de Segunda a Domingo (07h às 00h). Os horários mencionados são específicos para cada logradouro, de acordo com a necessidade do local.</p>
      <br/>
      <p>- As regras poderão ser alteradas seguindo a necessidade da TRANSALVADOR.</p>
    
  </div>
);

export default GeneralInfo;